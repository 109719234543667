import * as React from "react"
import { withPrismicPreview } from "gatsby-plugin-prismic-previews"
import { linkResolver } from "utils/linkResolver"
import { notFoundTitle, notFoundWrapper } from "./styles.module.css"
import { Helmet } from "react-helmet"
import { AnFadeIn } from "components"

const ContactPage = () => {
  return (
    <AnFadeIn>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Still Curious | 404`}</title>
      </Helmet>
      <section className={notFoundWrapper}>
        <h1 className={notFoundTitle}>PAGE NOT FOUND</h1>
      </section>
    </AnFadeIn>
  )
}

export default withPrismicPreview(ContactPage, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
])
